import { ApolloProvider } from '@apollo/react-hooks'
import CssBaseline from '@luce/ui-kit/components/atom/CssBaseline'
import Button from '@luce/ui-kit/components/button'
import theme from '@luce/ui-kit/themes/default'
import * as Sentry from '@sentry/react'
import AppBar from 'components/appbar/Appbar'
import SwitchBanner from 'components/appbar/switch-banner/SwitchBanner'
import { AuthProvider } from 'context/AuthContext'
import { MaintenanceModeProvider } from 'context/MaintenanceContext'
import {
  DateFnsUtils,
  PickersUtilsProvider,
  themeProvider as ThemeProvider,
} from 'luce-ui-components'
import { SnackbarProvider, useSnackbar } from 'notistack'
import React, { ErrorInfo, Suspense, lazy, useEffect } from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import { apolloClient } from 'services/apollo'
import { store } from './redux/configureStore'
import { ErrorBoundary } from 'react-error-boundary'
import { collectError } from 'services/sentry'
import { getIdentifiersFromStorage, storeIdentifiers } from 'utils/identifiers'
import { rudderanalytics } from 'utils/rudderstack'
import { GA_ID, LAST_GA_ID, getTrackingKey } from 'hooks/useUtmTracker'
import { getCookie } from 'utils/cookie'

const lazyWithReload: typeof lazy = (importer) => {
  const reloadImporter = async () => {
    try {
      return await importer()
    } catch (error: any) {
      if (
        error.message.toLowerCase().includes('dynamically imported module') ||
        error.message.toLowerCase().includes('javascript mime type') ||
        error.message.toLowerCase().includes('module script')
      ) {
        window.location.reload()
        return await importer()
      } else {
        throw error
      }
    }
  }
  return lazy(reloadImporter)
}

function SnackbarCloseButton({ snackbarKey }: any): React.ReactNode {
  const { closeSnackbar } = useSnackbar()

  return (
    <Button
      variant="text"
      customSize="xs"
      style={{ color: 'white' }}
      onClick={() => closeSnackbar(snackbarKey)}
    >
      Dismiss
    </Button>
  )
}

function onError(error: Error, info: ErrorInfo) {
  if (
    error.message.toLowerCase().includes('dynamically imported module') ||
    error.message.toLowerCase().includes('javascript mime type') ||
    error.message.toLowerCase().includes('importing a module')
  ) {
    window.location.reload()
  } else {
    collectError(error, {
      context: {
        name: 'Error boundary catch error',
        values: {
          componentStack: info.componentStack,
          digest: info.digest || '',
        },
      },
    })
  }
}

const ErrorBoundaryPage = lazyWithReload(() => import('pages/ErrorBoundary'))
const ClaimAccountByEmailContainer = lazyWithReload(
  () => import('components/auth/ClaimAccountByEmail.container')
)
const ClaimAccountByPhoneNumberContainer = lazyWithReload(
  () => import('components/auth/ClaimAccountByPhoneNumber.container')
)
const ForgotPasswordByEmailContainer = lazyWithReload(
  () => import('components/auth/ForgotPasswordByEmail.container')
)
const ForgotPasswordByPhoneNumberContainer = lazyWithReload(
  () => import('components/auth/ForgotPasswordByPhoneNumber.container')
)
const ResetPasswordContainer = lazyWithReload(
  () => import('components/auth/ResetPassword.container')
)
const SignInContainer = lazyWithReload(
  () => import('components/auth/SignIn.container')
)
const BookingContainer = lazyWithReload(
  () => import('components/booking/BookingPage.container')
)
const UpcomingVisitPage = lazyWithReload(
  () => import('components/visit/UpcomingVisit.page')
)
const HistoryVisitPage = lazyWithReload(
  () => import('components/visit/PastVisit.page')
)
const InvoiceContainer = lazyWithReload(
  () => import('components/invoice/Invoice.container')
)
const CreditAccountDetailPage = lazyWithReload(
  () => import('components/creditAccount/CreditAccountDetail.page')
)
const AccountCreditPage = lazyWithReload(
  () => import('components/creditAccount/AccountCredit.page')
)
const PackageCreditPage = lazyWithReload(
  () => import('components/creditAccount/PackageCredit.page')
)
const ProfileContainer = lazyWithReload(
  () => import('components/profile/Profile.container')
)
const SignUpContainer = lazyWithReload(
  () => import('components/auth/signup/SignUp.container')
)
const NotFoundPageContainer = lazyWithReload(
  () => import('components/dashboard/NotFoundPage.container')
)
const RateContainer = lazyWithReload(
  () => import('components/visit/Rate.container')
)
const InvoicePdfContainer = lazyWithReload(
  () => import('components/invoice/GenerateInvoicePdf')
)

const CsatRateContainer = lazyWithReload(
  () => import('components/customerReview/CsatRate.container')
)

const RescheduleContainer = lazyWithReload(
  () => import('components/reschedule/ReschedulePage.container')
)
const ManageVisitContainer = lazyWithReload(
  () => import('components/visit/ManageVisit.page')
)
const SelectBookingPage = lazyWithReload(
  () => import('components/booking/SelectBooking.page')
)
const RewardPage = lazyWithReload(
  () => import('components/reward/RewardPage.container')
)
const HomePage = lazyWithReload(() => import('components/home/HomePage'))
const PrivateRoute = lazyWithReload(
  () => import('components/route/PrivateRoute')
)
const GuestRoute = lazyWithReload(() => import('components/route/GuestRoute'))

const App: React.FC = () => {
  useEffect(() => {
    storeIdentifiers()
    getTrackingKey()
    const identifiers = getIdentifiersFromStorage()
    rudderanalytics.track('page_view', {
      ...identifiers,
    })
    // this timer is to solve: race condition between app render and gaId set into cookie
    setTimeout(() => {
      const lastGaId = getCookie(LAST_GA_ID)
      const gaId = getCookie(GA_ID)
      if (gaId && lastGaId !== gaId) getTrackingKey()
    }, 3000)
  }, [])
  return (
    <ErrorBoundary fallback={<ErrorBoundaryPage />} onError={onError}>
      <ThemeProvider theme={theme}>
        <ReduxProvider store={store}>
          <PickersUtilsProvider utils={DateFnsUtils}>
            <CssBaseline />
            <ApolloProvider client={apolloClient}>
              <MaintenanceModeProvider>
                <SnackbarProvider
                  maxSnack={3}
                  autoHideDuration={8000}
                  action={SnackbarCloseButton}
                >
                  <AuthProvider>
                    <Router>
                      <SwitchBanner />
                      <div>
                        <AppBar />
                        <Suspense fallback={null}>
                          <Switch>
                            <Route exact path="/">
                              <Redirect
                                to={{
                                  pathname: '/home',
                                  search: location.search,
                                }}
                              />
                            </Route>
                            <GuestRoute
                              path="/login"
                              component={SignInContainer}
                            />
                            <PrivateRoute
                              path="/upcoming"
                              component={UpcomingVisitPage}
                            />
                            <PrivateRoute
                              path="/history"
                              component={HistoryVisitPage}
                            />
                            <PrivateRoute
                              path="/invoice/package-credit/:creditAccountId"
                              component={CreditAccountDetailPage}
                            />
                            <PrivateRoute
                              path="/invoice/package-credit"
                              component={PackageCreditPage}
                              exact
                            />
                            <PrivateRoute
                              path="/invoice/account-credit"
                              component={AccountCreditPage}
                              exact
                            />
                            <PrivateRoute
                              path="/invoice"
                              component={InvoiceContainer}
                              exact
                            />
                            <PrivateRoute path="/home" component={HomePage} />
                            <PrivateRoute
                              path="/rewards"
                              component={RewardPage}
                            />
                            <Route
                              exact
                              path="/booking"
                              component={SelectBookingPage}
                            />
                            <Route
                              path="/booking/:type(home|aircon|upholstery|beauty)"
                              component={BookingContainer}
                            />
                            <Route
                              path="/reset-password"
                              component={ResetPasswordContainer}
                            />
                            <GuestRoute
                              path="/forgot-password-email"
                              component={ForgotPasswordByEmailContainer}
                            />
                            <GuestRoute
                              path="/forgot-password"
                              component={ForgotPasswordByPhoneNumberContainer}
                            />
                            <PrivateRoute
                              path="/profile"
                              component={ProfileContainer}
                            />
                            <GuestRoute
                              path="/claim-account-email"
                              component={ClaimAccountByEmailContainer}
                            />
                            <GuestRoute
                              path="/claim-account"
                              component={ClaimAccountByPhoneNumberContainer}
                            />
                            <GuestRoute
                              path="/signup"
                              component={SignUpContainer}
                            />
                            <Route
                              path="/aircon-reschedule"
                              component={RescheduleContainer}
                            />
                            <Route
                              path="/manage-visit"
                              component={ManageVisitContainer}
                            />
                            <Route path="/rate" component={RateContainer} />
                            <Route
                              path="/invoice-pdf"
                              component={InvoicePdfContainer}
                            />
                            <Route
                              path="/csat-rate"
                              component={CsatRateContainer}
                            />
                            <Route component={NotFoundPageContainer} />
                          </Switch>
                        </Suspense>
                      </div>
                    </Router>
                  </AuthProvider>
                </SnackbarProvider>
              </MaintenanceModeProvider>
            </ApolloProvider>
          </PickersUtilsProvider>
        </ReduxProvider>
      </ThemeProvider>
    </ErrorBoundary>
  )
}

export default Sentry.withProfiler(App)
