import Divider from '@luce/ui-kit/components/atom/Divider'
import Box from '@luce/ui-kit/components/base/Box'
import Color from '@luce/ui-kit/components/base/Color'
import IconButton from '@luce/ui-kit/components/button/IconButton'
import { makeStyles, type Theme } from '@luce/ui-kit/themes/ThemeProvider'
import MuiDrawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import MuiListItem from '@material-ui/core/ListItem'
import MuiListItemText from '@material-ui/core/ListItemText'
import LogoutModal from 'components/profile/LogoutModal'
// Need update ui kit
import useAuth from 'hooks/AuthHook'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  mobileScreen,
  useMediaQuery,
} from '../booking/ui-component/MediaQueryResponsive'

export type ActivePageRoute =
  | 'upcoming'
  | 'history'
  | 'invoice'
  | 'profile'
  | 'booking'

interface Props {
  showLogOutModal: boolean
  handleLogout(): void
  handleCloseLogout(): void
  activePageRoute: ActivePageRoute
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: '1200 !important' as any,
  },
  paperTab: {
    width: 287,
    paddingTop: theme.spacing(12),
  },
  paperMobile: {
    width: '100%',
    paddingTop: theme.spacing(12),
  },
  list: {
    padding: 0,
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  tabListItem: {
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2.5),
    paddingBottom: theme.spacing(2.5),
    '&:hover': {
      color: theme.palette.common.white,
      background: theme.palette.secondary.main,
      cursor: 'pointer',
      '& .MuiListItemIcon-root': {
        color: theme.palette.common.white,
      },
    },
  },
  listItemIcon: {
    minWidth: 32,
  },
  icon: {
    color: theme.palette.common.white,
  },
  subMenuList: {
    background: Color.primary[300],
    paddingLeft: theme.spacing(1),
    borderTop: `solid 1px ${Color.primary[400]}`,
    borderBottom: `solid 1px ${Color.primary[400]}`,
  },
  subMenuListItem: {
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  subMenuListText: {
    ...theme.typography.body2,
  },
  activeLink: {
    color: theme.palette.common.white,
    background: theme.palette.secondary.main,
    cursor: 'pointer',
    '& .MuiListItemIcon-root': {
      color: theme.palette.common.white,
    },
  },
}))

const NavigationDrawer: React.FC<any> = (props: Props) => {
  const { activePageRoute, handleLogout, handleCloseLogout, showLogOutModal } =
    props

  const classes = useStyles()
  const history = useHistory()

  const mobile = useMediaQuery(mobileScreen)
  const [isNavbarOpen, setIsNavbarOpen] = useState<boolean>(false)
  const [subMenuOpen, setSubMenuOpen] = useState<boolean>(false)

  const { hasCreditAccount } = useAuth()

  const toggleDrawer = (open: boolean): void => {
    setIsNavbarOpen(open)
    const switchBannerNode = document.getElementById('switch-banner')
    if (switchBannerNode) {
      switchBannerNode.style.display = open ? 'none' : 'flex'
    }
  }

  const showActiveLink = (item: string) => {
    if (activePageRoute.includes(item) && !subMenuOpen) {
      return classes.activeLink
    } else if (item === 'allServices' && subMenuOpen) {
      return classes.activeLink
    }
  }

  const handleNavigation = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    link: string
  ): void => {
    event.preventDefault()
    history.replace(`/${link}`)
    toggleDrawer(false)
    if (subMenuOpen) {
      setSubMenuOpen(false)
    }
  }

  useEffect(() => {
    return () => {
      const switchBannerNode = document.getElementById('switch-banner')
      if (switchBannerNode) {
        switchBannerNode.style.display = 'flex'
      }
    }
  }, [])

  return (
    <Box mr={1}>
      <Box>
        {isNavbarOpen ? (
          <IconButton
            className={classes.icon}
            iconName="CloseIcon"
            onClick={() => toggleDrawer(false)}
          />
        ) : (
          <IconButton
            className={classes.icon}
            iconName="MenuIcon"
            onClick={() => toggleDrawer(true)}
          />
        )}
      </Box>
      <MuiDrawer
        ModalProps={{ onBackdropClick: () => toggleDrawer(false) }}
        open={isNavbarOpen}
        className={classes.root}
        classes={{ paper: mobile ? classes.paperMobile : classes.paperTab }}
      >
        <MuiListItem
          onClick={(event: any) => handleNavigation(event, 'booking')}
          className={`
          ${classes.tabListItem}
          ${showActiveLink('booking')}`}
        >
          <MuiListItemText>Booking</MuiListItemText>
        </MuiListItem>
        <Divider className={classes.divider} />
        <List className={classes.list}>
          <MuiListItem
            onClick={(event: any) => handleNavigation(event, 'upcoming')}
            className={`
            ${classes.tabListItem}
            ${showActiveLink('upcoming')}`}
          >
            <MuiListItemText>Upcoming</MuiListItemText>
          </MuiListItem>
          <MuiListItem
            onClick={(event: any) => handleNavigation(event, 'history')}
            className={`
          ${classes.tabListItem}
          ${showActiveLink('history')}`}
          >
            <MuiListItemText>History</MuiListItemText>
          </MuiListItem>
          <MuiListItem
            onClick={(event: any) => handleNavigation(event, 'invoice')}
            className={`
          ${classes.tabListItem}
          ${showActiveLink('invoice')}`}
          >
            <MuiListItemText>
              {hasCreditAccount ? 'Credits & Invoices' : 'Invoices'}
            </MuiListItemText>
          </MuiListItem>
          <MuiListItem
            onClick={(event: any) => handleNavigation(event, 'rewards')}
            className={`
          ${classes.tabListItem}
          ${showActiveLink('reward')}`}
          >
            <MuiListItemText>Rewards</MuiListItemText>
          </MuiListItem>
        </List>
        <Divider className={classes.divider} />
        <MuiListItem onClick={handleLogout} className={classes.tabListItem}>
          <MuiListItemText>Logout</MuiListItemText>
        </MuiListItem>
      </MuiDrawer>
      {showLogOutModal && <LogoutModal handleClose={handleCloseLogout} />}
    </Box>
  )
}

export default NavigationDrawer
