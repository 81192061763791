export const fetchTrackingUrl = async (url: URL) => {
  const apiEndpoint = import.meta.env.VITE_REACT_APP_PUBLIC_GRAPHQL_ENDPOINT
  const baseUrl = new URL(apiEndpoint).origin

  const response = await fetch(
    `${baseUrl}/api/v1/service/url_shortener/generate`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ url, category: 'Tracking' }),
    }
  )

  if (!response.ok) {
    throw new Error('Response failed.')
  }

  const data = (await response.json()) as { shortened_url: string }
  return data.shortened_url
}
