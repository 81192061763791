import { PackageStatusEnum } from '__generated__/globalTypes'
import { type signOut } from '__generated__/signOut'
import { useMutation } from '@apollo/react-hooks'
import Backdrop from '@luce/ui-kit/components/atom/Backdrop'
import CircularProgress from '@luce/ui-kit/components/atom/CircularProgress'
import Session, {
  type UserSession,
  userSessionKey,
} from 'context/AuthContext/UserSession'
import { useClientQuery } from 'gqlhooks/Client/hooks/useClientQuery'
import React, { createContext, useCallback, useEffect, useState } from 'react'
import {
  getJwtFromRefreshToken,
  setJWT,
  setLogoutCallback,
} from 'services/apollo'
import { triggerEvent } from 'services/gtm'
import { setMonitoringUserScope } from 'services/sentry'
import { type Client } from 'ts/types/Client'
import { preventSilentSignin } from 'utils/helpers'

import SIGNOUT_MUTATION from './__graphql__/SignOutMutation.graphql'
import {
  CLIENT_SWITCH_TO_NEW_APP_KEY,
  useRedirectNewApp,
} from 'components/appbar/switch-banner/SwitchBanner'

export interface User {
  id: string
  firstName: string
  lastName: string
  phoneNumber: string
  email: string
  avatarUrl?: string
}

export interface AuthInterface {
  user?: User | null | undefined
  logout(): Promise<void>
  loggedIn(user: User, jwt?: string, jwtExpiry?: number): void
  loadingSession: boolean
  isSigningOut: boolean
  refetchClient(): Promise<void>
  client?: Client
  loadingClient: boolean
  hasCreditAccount: boolean
}

const restoredSession = Session.currentSession()

const AuthContext = createContext<AuthInterface>({
  user: undefined,
  logout: async () => {},
  loggedIn: () => {},
  loadingSession: false,
  isSigningOut: false,
  refetchClient: async () => {},
  client: undefined,
  loadingClient: false,
  hasCreditAccount: false,
})

const getTokenQuery = () => {
  const URL = window.location.search
  const params = new URLSearchParams(URL)

  const tokenParam = params.get('token')
  const refreshParam = params.get('refresh')
  const userParam = params.get('user')

  if (tokenParam && refreshParam && userParam) {
    try {
      const token = atob(tokenParam)
      const refreshToken = atob(refreshParam)
      const user = atob(userParam)
      const parsedUser = user ? (JSON.parse(user) as User) : null

      return {
        token,
        refreshToken,
        user: parsedUser,
      }
    } catch (error) {
      console.error('Error parsing token query', error)
      return null
    }
  }

  return null
}

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [session, setSession] = useState<UserSession>(restoredSession)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isJwtTokenExist, setIsJwtTokenExist] = useState<boolean>(false)

  const [signoutMutation, { loading: isSigningOut }] =
    useMutation<signOut>(SIGNOUT_MUTATION)
  const [
    clientQuery,
    { data, refetch: refetchClient, loading: loadingClient },
  ] = useClientQuery()

  useEffect(() => {
    if (session.user?.id && isJwtTokenExist) {
      clientQuery({
        id: session.user.id,
        packageStatus: PackageStatusEnum.ACTIVE,
      })
    }
  }, [session.user?.id, isJwtTokenExist])

  const client = data?.client

  const clearSession = () => {
    const session = Session.logout()
    setSession(session)
  }

  const logout = React.useCallback(async () => {
    preventSilentSignin()
    try {
      await signoutMutation()
      localStorage.removeItem('showPromo')
    } catch (err) {
      console.log(err)
    } finally {
      setJWT(undefined)
      clearSession()
    }
  }, [])

  const loggedIn = useCallback(
    (user: User, jwt?: string, _jwtExpiry?: number) => {
      if (jwt) {
        setJWT(jwt)
        setIsJwtTokenExist(true)
        localStorage.setItem('showPromo', '1')
      }
      const session = Session.login(user)
      console.log('Logged In. Session = ', session)
      setSession(session)
      triggerEvent('user_info', { session })
    },
    []
  )

  const checkSession = async (session: UserSession) => {
    const tokenData = getTokenQuery()
    if (tokenData?.token && tokenData?.user) {
      // Proceed login using query URL transferred from revamp app
      loggedIn(tokenData.user, tokenData.token)
      localStorage.setItem('refreshToken', tokenData.refreshToken)
      localStorage.setItem(CLIENT_SWITCH_TO_NEW_APP_KEY, '0')
    } else if (session.user) {
      try {
        const result = await getJwtFromRefreshToken()

        if (result) {
          setIsJwtTokenExist(true)
        } else {
          setIsJwtTokenExist(false)
        }
      } catch (err) {
        if (err.status === 403 || err.status === 404) {
          const session = Session.logout()
          setIsJwtTokenExist(false)
          setSession(session)
        }
      }
    }
    setIsLoading(false)
  }

  const user = session?.user

  const onStorage = useCallback(
    (e: StorageEvent) => {
      if (e.key === userSessionKey) {
        console.log('oldValue', e.oldValue)
        console.log('newValue', e.newValue)
        if (e.oldValue && !e.newValue) {
          console.log('User logged out on another tab')
          setJWT(undefined)
          const session = Session.currentSession()
          setSession(session)
        } else if (!e.oldValue && e.newValue) {
          console.log('User logged in on another tab')
        }
      }
    },
    [user]
  )

  useEffect(() => {
    checkSession(session)
    setLogoutCallback(clearSession)
    window.addEventListener('storage', onStorage)
    return () => {
      window.removeEventListener('storage', onStorage)
    }
  }, [])

  useEffect(() => {
    if (client) {
      loggedIn({
        id: client.id,
        firstName: client.contacts[0].firstName,
        lastName: client.contacts[0].lastName,
        email: client.user.email,
        phoneNumber: client.user.phoneNumber ?? '',
        avatarUrl: undefined,
      })
      setMonitoringUserScope({
        clientId: client.id,
        email: client.user.email,
        phoneNumber: client.user.phoneNumber ?? '',
        fullName: `${client.contacts[0].firstName} ${client.contacts[0].lastName}`,
      })
    }
  }, [client])

  useRedirectNewApp(!!client)

  return (
    <AuthContext.Provider
      value={{
        loadingSession: isLoading,
        user: session.user,
        logout,
        loggedIn,
        isSigningOut,
        client,
        refetchClient: async () => {
          await refetchClient()
        },
        loadingClient,
        hasCreditAccount: Boolean(client?.creditAccounts.length),
      }}
    >
      {!isLoading && children}
      <Backdrop open={isSigningOut || isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </AuthContext.Provider>
  )
}

AuthContext.displayName = 'AuthContext'

export default AuthContext
