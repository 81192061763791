import { fetchTrackingUrl } from 'services/urlShortener'
import { getCookie, setCookie } from 'utils/cookie'

const SALESPERSON_COOKIE_KEY = '_luce_salesperson_shortcode'
const SALESPERSON_PARAM_KEY = 'sales'
export const AD_TRACKING_COOKIE_KEY = '_luce_tracking_key'
export const GA_ID = '_ga'
export const LAST_GA_ID = '_last_ga'

const storeUtmData = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const utmKeys = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
  ]

  for (const key of utmKeys) {
    const cookieValue = getCookie(key)

    if (!cookieValue) {
      const paramValue = urlParams.get(key)

      if (paramValue) {
        setCookie(key, paramValue)
      }
    }
  }
}

const getSalespersonData = () => {
  const urlParams = new URLSearchParams(window.location.search)

  const salesParams = urlParams.get(SALESPERSON_PARAM_KEY)

  if (salesParams) {
    return setCookie(SALESPERSON_COOKIE_KEY, salesParams)
  }

  const salesPersonShortCode = getCookie(SALESPERSON_COOKIE_KEY)

  if (salesPersonShortCode) {
    return salesPersonShortCode
  }

  return null
}

const getTrackingKey = async () => {
  const LUCE_WEBSITE_URL = import.meta.env.VITE_LUCE_WEBSITE_URL ?? ''
  const gaId = getCookie(GA_ID) || ''

  try {
    const url = new URL(LUCE_WEBSITE_URL)
    url.searchParams.set('gaid', gaId)
    const trackingUrl = await fetchTrackingUrl(url)
    const key = new URL(trackingUrl).searchParams.get('origin') || ''
    setCookie(AD_TRACKING_COOKIE_KEY, key)
    setCookie(LAST_GA_ID, gaId)
    return key
  } catch (error) {
    console.error('Failed to shorten the url:', error)
    return ''
  }
}

const getTrackingKeyFromCookie = () => {
  return getCookie(AD_TRACKING_COOKIE_KEY) || ''
}

const useUtmTracker = () => {
  storeUtmData()
  getSalespersonData()
}

export {
  useUtmTracker,
  getSalespersonData,
  getTrackingKeyFromCookie,
  getTrackingKey,
}
