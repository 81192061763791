import * as rudderanalytics from 'rudder-sdk-js'
import { isLocal } from './helpers'

const WRITE_KEY = import.meta.env.VITE_REACT_RUDDERSTACK_WRITE_KEY
const DATA_PLANE_URL = import.meta.env.VITE_REACT_RUDDERSTACK_DATA_PLANE_URL

if (!isLocal()) {
  rudderanalytics.load(WRITE_KEY, DATA_PLANE_URL)
}

export { rudderanalytics }
